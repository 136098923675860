import React, { useEffect } from "react";
import { Heading } from "components/Heading";
import Swal from "sweetalert2";

const TermsOfService = () => {
  return (
    <div className="container-xs py-8 sm:p-6">
      <h1 className="text-4xl text-center mb-2">Terms of Service</h1>
      <h3 className="text-lg text-center font-semibold">
        (Effective Date: 10-10-2024)
      </h3>
      <div className="my-6 space-y-5">
        <p>
          Welcome to soomuch.ai! By accessing or using our website and services,
          you agree to comply with and be bound by the following Terms of
          Service. If you do not agree with these terms, please do not use our
          services.
        </p>
        <div className="space-y-2">
          <p className="text-lg font-medium">1. Acceptance of Terms</p>
          <p>
            By accessing or using the services provided by soomuch.ai, you
            confirm that you accept these Terms of Service and that you agree to
            comply with them. If you do not agree to these terms, you must not
            use our services.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">2. Changes to Terms</p>
          <p>
            We reserve the right to modify these Terms of Service at any time.
            Changes will be effective immediately upon posting on this page.
            Your continued use of the services after any modifications signifies
            your acceptance of the new Terms of Service.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">3. Services Provided</p>
          <p>
            soomuch.ai offers [describe services briefly, e.g., AI-based
            solutions, data analysis tools, etc.]. We strive to provide
            high-quality services, but we cannot guarantee specific results.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">4. User Responsibilities</p>
          <p className="font-semibold">By using our services, you agree to:</p>
          <ul className="leading-5 list-disc pl-5">
            <li>Provide accurate and up-to-date information.</li>
            <li>
              Use the services in compliance with all applicable laws and
              regulations.
            </li>
            <li>
              Not engage in any conduct that could harm our services or users.
            </li>
          </ul>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">5. Account Security</p>
          <p>
            If you create an account with us, you are responsible for
            maintaining the confidentiality of your account credentials and for
            all activities that occur under your account. You agree to notify us
            immediately of any unauthorized use of your account.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">6. Intellectual Property</p>
          <p>
            All content and materials provided by soomuch.ai, including but not
            limited to text, graphics, logos, and software, are the property of
            soomuch.ai or our licensors and are protected by copyright and other
            intellectual property laws. You may not reproduce, distribute, or
            create derivative works without our explicit permission.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">7. Limitation of Liability</p>
          <p>
            To the fullest extent permitted by law, soomuch.ai shall not be
            liable for any direct, indirect, incidental, special, consequential,
            or punitive damages arising from your use of, or inability to use,
            our services.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">8. Indemnification</p>
          <p>
            You agree to indemnify and hold harmless soomuch.ai, its affiliates,
            and their respective officers, directors, and employees from any
            claims, losses, liabilities, damages, costs, or expenses (including
            reasonable attorneys' fees) arising out of your use of our services
            or violation of these Terms of Service.
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">9. Governing Law</p>
          <p>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction]. Any disputes
            arising under or in connection with these terms shall be subject to
            the exclusive jurisdiction of the courts of [Your Jurisdiction].
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">10. Contact Information</p>
          <p>
            If you have any questions about these Terms of Service, please
            contact us at: <strong>Email: support@soomuch.ai</strong>
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-lg font-medium">11. Entire Agreement</p>
          <p>
            These Terms of Service constitute the entire agreement between you
            and soomuch.ai regarding your use of our services and supersede any
            prior agreements.{" "}
          </p>
        </div>
        <p>
          <strong>Disclaimer:</strong> This is a general template and may not
          cover all aspects of your service. It is highly recommended to consult
          with a legal professional to ensure compliance with specific legal
          requirements and regulations applicable to your business and
          jurisdiction.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
