import { Img, Heading } from "./..";
import React, { useState } from "react";

interface Props {
  className?: string;
}

export default function Header({ ...props }: Props) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  console.log('isNavOpen', isNavOpen);

  return (
    <header
      {...props}
      className={`${props.className} fixed top-0 z-[999] w-full py-5 bg-white-A700_01 shadow-sm`}
    >
      {/* <nav className="laptop:container-xs flex items-center justify-between">
        <div className="flex items-center flex-shrink-0 text-white mr-6 sm:ml-5">
          <Img onClick={() => {
            window.location.href = "/"
          }} src="images/img_header_logo.png" alt="header logo" className="cursor-pointer h-[49px] w-[232px] object-contain" />
        </div>

        <div className="block laptop:hidden sm:mr-5">
          <button id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white"
            onClick={handleNavToggle}>
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>

        <div className={`w-full flex-grow laptop:flex laptop:items-center laptop:w-auto ${isNavOpen ? '' : 'hidden'}  laptop:block pt-6 laptop:pt-0`} id="nav-content">
          <ul className="list-reset laptop:flex justify-end flex-1 items-center gap-[30px]">
            <li>
              <a href="#">
                <Heading size="lg" as="h6" className="!text-black-900">
                  Our Journey
                </Heading>
              </a>
            </li>
            <li>
              <a href="#">
                <Heading size="lg" as="h6" className="!text-black-900">
                  We are Different
                </Heading>
              </a>
            </li>
            <li>
              <a href="#">
                <Heading size="lg" as="h6" className="!text-black-900">
                  Add On’s
                </Heading>
              </a>
            </li>
            <li>
              <a href="#">
                <Heading size="lg" as="h6" className="!text-black-900">
                  Contact Us
                </Heading>
              </a>
            </li>
          </ul>
        </div>
      </nav> */}
      <nav className=" laptop:container-xs flex justify-between px-2">
        <div className="flex items-center ml-5">
          <Img onClick={() => {
            window.location.href = "/"
          }} src="images/soomuch_logo.jpg" alt="header logo" className="cursor-pointer h-[55px] w-[250px] object-contain" />
        </div>
        <div className="flex items-center gap-3">
          <div
            className={`navLinks sm:h-[85vh] duration-500 sm:absolute static sm:w-[50%] w-full laptop:h-auto  bg-white-A700_01 flex gap-[1.5vw] top-[100%] ${isNavOpen ? "left-0" : "left-[-100%]"
              } px-5 md:py-0 py-3`}
          >
            <ul className="flex laptop:flex-row md:flex-row sm:flex-col  md:gap-[2vw] gap-8 sm:m-5">
              <li className="relative pr-3 md:pr-0 py-1 ">
                <a href="/" onClick={handleNavToggle}> <Heading size="lg" as="h6" className="!text-black-900">
                  Our Journey
                </Heading>
                </a>
              </li>
              <li className="relative max-w-fit pr-3 md:pr-0 py-1">
                <a href="/digital-marketing" onClick={handleNavToggle}> <Heading size="lg" as="h6" className="!text-black-900">
                  We are Different
                </Heading></a>
              </li>
              <li className="relative max-w-fit pr-3 md:pr-0 py-1">
                <a href="/customer-satisfaction" onClick={handleNavToggle}> <Heading size="lg" as="h6" className="!text-black-900">
                  Add On’s
                </Heading></a>
              </li>
              <li className="relative max-w-fit pr-3 md:pr-0 py-1">
                <a href="/fee-collection-process" onClick={handleNavToggle}><Heading size="lg" as="h6" className="!text-black-900">
                  Contact Us
                </Heading></a>
              </li>
              {/* <li className="relative max-w-fit pr-3 md:pr-0 py-1">
                <a href="/ai-powered-managment-hub" onClick={handleNavToggle}>AiPowered Managment Hub</a>
              </li> */}
            </ul>
          </div>
          <div className="flex items-center gap-2 hidden sm:block sm:mr-5">
            <button id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white"
              onClick={handleNavToggle}>
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}
