import { Link } from "react-router-dom";
import { Img, Text, Button, Heading } from "./..";
import React from "react";

interface Props {
  className?: string;
}

export default function Footer({ ...props }: Props) {
  return (
    <footer
      {...props}
      className={`${props.className} w-full py-[60px] bg-indigo-900 sm:p-0`}
    >
      <div className="w-[85%] md:w-[65%] mx-auto text-center my-5 md:my-16">
        <Text as="p">
          SOOMUCH.AI is India's pioneering business ecosystem tailored
          specifically for educational institutions. By integrating cutting-edge
          technology and proven strategies from successful global businesses, we
          empower schools to enhance their operations, improve communication,
          and increase revenue. Our comprehensive suite of modules, including
          AUTO CONNECT, SOCIAL HUB, Parental Insight, Auto Fee Hub, and
          AI-Powered Management Hub, ensures a holistic approach to school
          management.
        </Text>
      </div>

      <div className="container mx-auto px-12 py-8 flex flex-wrap justify-between gap-8">
        {/* Auto Connect */}
        <div className=" flex flex-col w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Auto Connect
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <a href="#">
                <Text as="p">Multilingual Communication</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Seamless Admissions</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Parent Engagement</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Efficient Messaging</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Real-time Updates</Text>
              </a>
            </li>
          </ul>
        </div>

        {/* Social Hub */}
        <div className="flex flex-col   w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Social Hub
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <a href="#">
                <Text as="p">Online Presence</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Social Media Marketing</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Parental Referrals</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">School Branding</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Community Engagement</Text>
              </a>
            </li>
          </ul>
        </div>

        {/* Parental Insight */}
        <div className="flex flex-col   w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Parental Insight
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <a href="#">
                <Text as="p">Parent Feedback</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Survey Analysis</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Improved Satisfaction</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Proactive Solutions</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Relationship Building</Text>
              </a>
            </li>
          </ul>
        </div>

        {/* Auto Fee Hub */}
        <div className="flex flex-col   w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Auto Fee Hub
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <a href="#">
                <Text as="p">Automated Fee Collection</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Timely Reminders</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Reduced Arrears</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Integrated Payments</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Financial Management</Text>
              </a>
            </li>
          </ul>
        </div>

        {/* AI-Powered Management Hub */}
        <div className="flex flex-col   w-[220px]">
          <Heading size="lg" as="h5" className="mb-1 !font-playfairdisplay">
            AI-Powered Management Hub
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <a href="#">
                <Text as="p">Intuitive Dashboard</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Data Migration</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Virtual Assistants</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Customization Options</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Operational Efficiency</Text>
              </a>
            </li>
          </ul>
        </div>

        {/* Quick Links */}
        <div className="flex flex-col    w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Quick Links
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <a href="#">
                <Text as="p">Home</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">About Us</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Modules</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Testimonials</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Blog</Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text as="p">Contact Us</Text>
              </a>
            </li>
          </ul>
        </div>

        {/* Legal */}
        <div className="flex flex-col    w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Legal
          </Heading>
          <ul className="space-y-2 flex-grow">
            <li>
              <Link to="/privacy-policy#privacy-policy">
                <Text as="p">Privacy Policy</Text>
              </Link>
            </li>
            <li className="hover:cursor-pointer">
              <Link to="/privacy-policy#terms-of-service">
                <Text as="p">Terms of Service</Text>
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy#cookie-policy">
                <Text as="p">Cookie Policy</Text>
              </Link>
            </li>
            <li className="">
              <Link to="/refund-policy">
                <Text as="p">Refund and Cancellation Policy</Text>
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="flex flex-col   w-[220px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            Contact
          </Heading>
          <ul className="space-y-2 flex-grow">
            {/* <li>
              <Heading size="s" as={"h6"}>
                Address
              </Heading>
              <Text as="p">
                SOOMUCH.AI Headquarters <br /> 123 Business Avenue, Tech City,
                India
              </Text>
            </li>
            <li>
              <Heading size="s" as={"h6"}>
                Phone
              </Heading>
              <Text as="p">
                General Inquiries: +91 12345 67890 <br /> Admissions: +91 12345
                67891 <br />
                Support: +91 12345 67892
              </Text>
            </li> */}
            <li>
              {/* <Heading size="s" as={"h6"}>
                Email
              </Heading> */}
              <Text as="p">Email id: support@soomuch.ai</Text>
            </li>
          </ul>
        </div>

        {/* News Letter */}
        <div className="flex flex-col flex-1 max-w-[500px]">
          <Heading size="lg" as="h5" className="mb-4 !font-playfairdisplay">
            News Letter
          </Heading>
          <div className="flex flex-col items-start justify-start gap-8">
            <Text as="p" className="">
              Ready to transform your educational institution? Subscribe to
              SOOMUCH.AI today and experience the future of school management.
              Let's work together to create a more efficient, productive, and
              successful learning environment.
            </Text>
            <div className="flex items-center justify-between gap-5 self-stretch rounded-br-[20px] rounded-tr-[20px] bg-blue_gray-800 pl-6 sm:pl-5">
              <a href="#">
                <Text as="p">Your Email Address</Text>
              </a>
              <Button
                size="sm"
                color="deep_purple_A200_03_purple_400_01"
                className="min-w-[128px] rounded-[20px] font-poppins font-bold sm:px-5"
              >
                SUBSCRIBE
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center w-full px-8 mb-5 mt-[50px] sm:flex-col sm:mt-[30px]">
        <Text as="p" className="!text-[#ffffff] !font-medium">
          <span className="text-[#ffffff]">
            Soomuch Smart Solutions PVT Limited | Copyright ©2024
          </span>
        </Text>
        <Img
          src="images/img_group_1707479657.svg"
          alt="image"
          className="sm:m-5"
        />
      </div>
    </footer>
  );
}
